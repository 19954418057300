import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import dayjs from "dayjs";
import axios from "axios";
import { FaArrowDown } from "react-icons/fa";

import Layout from "components/Layout";
import SEO from "components/Seo";
import CtaButton from "components/CtaButton";

import { cartContext } from "providers/CartProvider";
import { StyledEvent } from "styles/styles";

function getMonthName(m) {
  return dayjs().month(m).format("MMMM");
}

function handlePrintClick() {
  return window && window.print();
}

function getDay(d) {
  return dayjs(d).format("DD");
}

function getMonthShort(d) {
  return dayjs(d).format("MMM");
}

function formatDate(d) {
  return dayjs(d).format("DD MMM, YYYY");
}

const thisYear = dayjs().year();

const Event = ({ event }) => (
  <StyledEvent>
    <div className={`bigdate ${event.isEvent ? "is-event" : ""}`}>
      <span className="day">{getDay(event.date)}</span>
      <span className="month">{getMonthShort(event.date)}</span>
      {event.isEvent ? (
        <span style={{ padding: "12px 0 0" }}>(REQUAL.)</span>
      ) : (
        ""
      )}
    </div>

    <div className="details">
      <h5 className="e-title">
        <strong>{event.title}</strong>
      </h5>
      <p className="e-time">
        Start Time: <strong>{event.timestart}</strong> <br />
        End Date:{" "}
        <strong>
          {event.datestop ? formatDate(event.datestop) : "(not set)"}
        </strong>
      </p>
      {event.note && (
        <p className="e-desc" style={{ whiteSpace: "pre-line" }}>
          {event.note}
        </p>
      )}
      {event.url && (
        <p className="e-desc">
          <a href={event.url} rel="noreferrer" target="_blank">
            Link
          </a>
        </p>
      )}
    </div>
  </StyledEvent>
);

const CalendarsPage = () => {
  const [curMonth, setCurMonth] = useState(dayjs().month());
  const [curYear, setCurYear] = useState(thisYear);
  const [scheduleData, setScheduleData] = useState([]);

  const siteContext = useContext(cartContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/courses/schedules/calendar`,
          {
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        // setScheduleData(response.data);
        // console.log(
        //   "🚀 ~ file: calendars.js:74 ~ fetchData ~ response.data:",
        //   response.data
        // );

        const eventsResponse = await axios.get(
          `${process.env.GATSBY_API_URL}/otherevents`,
          {
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        const eventsData = eventsResponse.data.map(evt => {
          evt.isEvent = true;
          return evt;
        });

        const combinedData = [].concat(...eventsData, ...response.data);
        combinedData.sort((a, b) => new Date(a.date) - new Date(b.date));

        setScheduleData(combinedData);
      } catch (e) {
        siteContext.makeToast("Unable to fetch schedule data.", "error");
      }
    }

    fetchData();
  }, [siteContext]);

  function getFilteredData() {
    const newData = scheduleData.filter(x => {
      const theMonth = dayjs(x.date).month();
      const theYear = dayjs(x.date).year();

      // remove private classes
      if (x?.coursecats?.[0]?.slug === "private") {
        return false;
      }

      if (+theMonth === +curMonth && +theYear === +curYear) {
        return true;
      }

      return false;
    });

    return newData;
  }

  const filteredData = getFilteredData();
  const monthName = getMonthName(curMonth);

  return (
    <Layout pageInfo={{ pageName: "calendars" }}>
      <SEO title="Class Calendars" />

      <section>
        <Container>
          <Row>
            <Col xs="12">
              <h2>Printable Class Calendars</h2>

              <p>
                <select
                  value={curMonth}
                  onChange={e => setCurMonth(+e.target.value)}
                  className="form-control ml-2"
                  style={{ width: "100px", display: "inline-block" }}
                >
                  <option value={0}>Jan</option>
                  <option value={1}>Feb</option>
                  <option value={2}>Mar</option>
                  <option value={3}>Apr</option>
                  <option value={4}>May</option>
                  <option value={5}>Jun</option>
                  <option value={6}>Jul</option>
                  <option value={7}>Aug</option>
                  <option value={8}>Sep</option>
                  <option value={9}>Oct</option>
                  <option value={10}>Nov</option>
                  <option value={11}>Dec</option>
                </select>

                <select
                  value={curYear}
                  onChange={e => setCurYear(+e.target.value)}
                  className="form-control ml-2"
                  style={{ width: "100px", display: "inline-block" }}
                >
                  <option value={+thisYear - 1}>{thisYear - 1}</option>
                  <option value={+thisYear}>{thisYear}</option>
                  <option value={+thisYear + 1}>{thisYear + 1}</option>
                </select>

                <button
                  className="btn btn-sm btn-success ml-3 d-inline-flex flex-row align-items-center"
                  type="button"
                  onClick={handlePrintClick}
                >
                  <FaArrowDown className="mr-1" /> PRINT CALENDAR
                </button>
              </p>

              <p className="font-weight-bold">
                To print a calendar: Select the month (and year) you wish to
                print above, and then click the big{" "}
                <span className="text-success">green</span> button.
              </p>
              <hr />
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col xs="12">
              <div className="landscape-print big-cal" id="divToPrint">
                <h4 className="text-center">
                  {monthName} {curYear} Schedule
                </h4>

                <p className="text-center m-0 pb-5" style={{ lineHeight: 1 }}>
                  <b>D & D Security Training Academy</b> <br />
                  (703) 333-2822
                </p>

                <div>
                  {filteredData.map((item, i) => (
                    <div className="cal-item" key={i}>
                      <Event event={item} />
                    </div>
                  ))}

                  {filteredData.length === 0 && (
                    <p>
                      No schedule available for this month. Please check back,
                      especially if you're viewing more than 2 months past the
                      current month.
                    </p>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bluedark">
        <Container>
          <Row>
            <Col lg="10" className="mx-auto text-center">
              <p className="mini-lead">Get Started</p>
              <h2>Your Future in Security Starts Here</h2>
              <p className="intro text-white">
                Enroll now to start your journey towards a better future.
              </p>

              <CtaButton
                label="View Classes"
                url="/class-schedules"
                className="mt-3"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default CalendarsPage;
